Main Components animations START
.component-enter {
    transform: translateY(-100%);
  }
  
  .component-enter.component-enter-active {
    transform: translateY(0);
    transition: transform 300ms ease-out;
  }
  
  .component-leave {
    transform: translateY(0);
  }
  
  .component-leave.component-leave-active {
    transform: translateY(-100%);
    opacity: 0;
    transition: 300ms ease-in;
  }
  
  .component-appear {
    opacity: 0;
  }
  
  .component-appear.component-appear-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  /*Main Components animations END*/
  
  
  
  /*Books animations START*/
  .book-enter {
    transform: translateX(-100%);
  }
  
  .book-enter.book-enter-active {
    transform: translateX(0);
    transition: transform 500ms ease-out;
  }
  
  .book-leave {
    transform: translateX(0);
  }
  
  .book-leave.book-leave-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: 300ms ease-in;
  }
  
  .book-appear {
    opacity: 0;
  }
  
  .book-appear.book-appear-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  /*Books animations END*/
  
  
  /*Verses animations START*/
  .verse-enter {
    transform: translateX(-100%);
  }
  
  .verse-enter.verse-enter-active {
    transform: translateX(0);
    transition: transform 500ms ease-out;
  }
  
  .verse-leave {
    transform: translateX(0);
  }
  
  .verse-leave.verse-leave-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: 300ms ease-in;
  }
  
  .verse-appear {
    opacity: 0;
  }
  
  .verse-appear.verse-appear-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  /*Verses animations END*/
  
  
  body {
    /*overflow: hidden;*/
  }
  
  
  .tag_notes_collection_text {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-style: italic;
    font-size: 14;
  }

  .pagination {
    display: flex;
    justify-content: space-around;
    list-style: none;
    cursor: pointer;
    font-size: 12px;
    width: 70%;
    margin: 0 auto;
  }
  
  .pagination a {
    padding: 3px 7px 3px 7px;
    margin: 1px;
    border-radius: 3px;
    border: 1px solid #324599;
    color: #324599;
  }
  
  .pagination__link {
    font-weight: bold;
  }
  
  .pagination__link--active a {
    color: #fff;
    background: #324599;
  }
  
  .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }