.verses-resizable {
	display: inline-block;
	vertical-align: top;
	margin-right: 25px;
	padding: 140px 10px 5px 25px;
	height: 100%;
	border-radius: 10px;
	background-color: #fafafa;
	font-size: 15px;
	white-space: normal;
	z-index: 1;
}

.linked-verse {
	/* background-color: #ffffff; */
	animation: ColorFade 3s ease-in-out forwards;
	border-radius: 8px;
	padding: 2px;
}

@keyframes ColorFade {
	from {
		background-color: #8fbee7;
		/* background-color: #E1F0FD; */
	}
	to {
		background-color: none;
		/* background-color: #98c1e46c; */
	}
}
