.datepicker{
	width: 100%;
	height: 45px;
	background-color: #f1f1f1;
	border: #979797 solid 1px;
	padding: 0px 10px;
	margin:5px 3px;
}
.react-datepicker-wrapper{
	width: calc(100% - 6px);
}