.switch {
	position: relative;
	display: inline-block;
	width: 150px;
	height: 25px;
}

.switch input {
	display: none;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 3;
	background-color: #2a74b9;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: '';
	height: 15px;
	width: 15px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked+.slider {
	background-color: #22a8ca;
}

input:focus+.slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
	-webkit-transform: translateX(127px);
	-ms-transform: translateX(127px);
	transform: translateX(127px);
}

/*------ ADDED CSS ---------*/
.on {
	display: none;
}

.on,
.off {
	color: white;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	font-size: 10px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

input:checked+.slider .on {
	display: block;
}

input:checked+.slider .off {
	display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.react-tooltip-lite {
	background: #333;
	color: white;
	font-size: 12px;
	text-overflow: ellipsis;
	padding: 5px !important;
}

.react-tooltip-lite-arrow {
	border-color: #333;
}