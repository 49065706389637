.jodit_container .jodit_toolbar,
.jodit_container .jodit_statusbar {
  background-color: #a3b6d4 !important;
}

.tox-notification {
  display: none !important;
}

.collectionNoteForm {
  height: 100%;
  padding: 100px 0 0;
  overflow: hidden;
  /* overflow-y: auto; */
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container {
  width: 90%
}

.customDatePickerWidth>div>div.react-datepicker__input-container input {
  width: 100%;
}

.jodit_wysiwyg {
  min-height: 160px !important;
}

.content-pane-title {
  margin: 0 30px 0 25px;
  padding: 15px 0 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 25px - 30px);
  height: 110px;
  overflow: hidden;
  padding-top: 25px;
  margin-right: 0;
  padding-left: 0;
}

div.collectionNoteForm_flexRow {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding-right: 10px;
}

.collectionNoteForm_topRow-item {
  width: 100%;
  /* width: 80%; */
  height: 45px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 6;
}

.collectionNoteForm_topRow-item--show {
  width: 100%;
}

.collectionNoteForm_topRow-item:first-child {
  margin-right: 5px;
}

.collectionNoteForm_topRow-item:last-child {
  margin-left: 5px;
}

.collectionNoteForm_title-input {

  height: 100%;
  /* border: none; */
  border: 1px solid #dadada;
  padding: 12px 10px;
  /* color: rgba(0, 0, 0, 0.849); */
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  background-color: #e9e9e9;
  font-size: 16px;
}

.collectionNoteForm_title-input:focus {
  background-color: #ffffff;
  /* box-shadow: inset 0 0 0 2px #000; */
  outline: none;
}

.collectionNoteForm_title-input::placeholder {
  color: #939393;
}

.collectionNoteForm_categorySelect {
  width: 100%;
  height: 100%;
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 18px;
  color: #cccccc;
}

.collectionNoteForm_categorySelect:hover {
  cursor: pointer;
}

.collectionNoteForm_categorySelect option:first-child {
  color: #c6c8ca;
}

.collectionNoteForm_categorySelect option:nth-child(n + 2) {
  color: #393f47;
}

.collectionNoteForm_topRow-item .ReactTags__selected {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #cccccc;
}

.collectionNoteForm_topRow-item div.ReactTags__tagInput input.ReactTags__tagInputField,
.collectionNoteForm_topRow-item div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  min-width: 100px;
  border: none;
  background-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 18px;
  color: #393f47;
}

.collectionNoteForm_topRow-item div.ReactTags__selected span.ReactTags__tag {
  font-size: 16px;
  color: #393f47;
}

.collectionNoteForm_topRow-item div.ReactTags__tagInput input.ReactTags__tagInputField::placeholder {
  color: #cccccc;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.collectionNoteForm_topRow-item::-webkit-scrollbar {
  height: 14px;
}

.collectionNoteForm_topRow-item .select-category .selectCategory__clear-indicator {
  display: none;
}

.collectionNoteForm_topRow-item .select-tag .selectCategory__clear-indicator {
  display: none;
}

.selectCategory__value-container::-webkit-scrollbar {
  height: 4px;
}

.select-category .selectCategory__multi-value {
  min-width: min-content;
}

.select-tag .selectCategory__multi-value {
  min-width: min-content;
}

.content_pane_wrapper-breadcrumbs {
  color: #6a7583;
  cursor: pointer;
}

h2.content_pane_title-breadcrumbs {
  padding-bottom: 15px;
  font-size: 16px;
}

.select-category {
  /* width: 15.5vw; */
  width: 100%;
  height: 100%;
}

.select-tag {
  /* width: 14vw; */
  width: 100%;
  height: 100%;
}

.lds-footnote-switch {
  align-items: center;
  justify-content: space-around;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px;
  line-height: 16px;
}

.select-category .selectCategory__control {
  box-shadow: none;
  border: 1 solid #dadada;
  background-color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  color: #393f47;
  border-radius: 0;
  height: 100%;
}

.select-tag .selectCategory__control {
  box-shadow: none;
  border: 1 solid #dadada;
  background-color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  color: #393f47;
  border-radius: 0;
  height: 100%;
}

.css-12jo7m5 {
  font-size: 16px !important;
}

.select-category .selectCategory__value-container {
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 200px;
}

.select-tag .selectCategory__value-container {
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 200px;
}

.select-tag .selectCategory__value-container::-webkit-scrollbar:hover {
  /* display: none; */
  /* background: green; */
  border-top: 1px solid #7d7d7d;
  border-bottom: 1px solid #7d7d7d;
  /* padding: 1px; */
  height: 15px;
  /* width: 100%; */
}

.select-category .selectCategory__value-container::-webkit-scrollbar:hover {
  /* display: none; */
  /* background: green; */
  border-top: 1px solid #7d7d7d;
  border-bottom: 1px solid #7d7d7d;
  /* padding: 1px; */
  height: 15px;
  /* width: 100%; */
}

.select-tag .selectCategory__value-container::-webkit-scrollbar-thumb:hover {
  /* display: none; */
  background: #7d7d7d;
  height: 15px;
  /* width: .5%; */
  border-radius: 5px;
}

.select-category .selectCategory__value-container::-webkit-scrollbar-thumb:hover {
  /* display: none; */
  background: #7d7d7d;
  height: 15px;
  /* width: .5%; */
  border-radius: 5px;
}



.selectCategory__placeholder {
  font-size: 17px;
  /* font-weight: bold; */
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  opacity: 0.5;
}

.select-content-pane-title-wrapper {
  overflow: visible;
}

div.collectionNoteForm_topRow-item-overflow {
  overflow-x: auto;
}

.select-category .selectCategory__control-is-focused,
.select-category .selectCategory__control-is-focused:hover {
  /*border-width: 2px;*/
  border: none;
  color: #000;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 2px #000;
}

.select-tag .selectCategory__control-is-focused,
.select-tag .selectCategory__control-is-focused:hover {
  /*border-width: 2px;*/
  border: none;
  color: #000;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 2px #000;
}

.myLabelClass {
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.myLabelClass:hover {
  background-color: #eaeaea;
  border-radius: 5px;
}

.message {
  font-size: 15px;
  line-height: 22px;
}

.collectionNoteForm .selectCategory__placeholder {
  color: rgba(0, 0, 0, 0.849);
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 5px;
  /*margin: -5px 0;*/
  padding: 1px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: calc(100% - 55px);
  border-top: 1px solid #000;
  display: none;
}

.Resizer.horizontal:hover {
  /*border-top: 5px solid rgba(0, 0, 0, 0.5);*/
  /*border-bottom: 5px solid rgba(0, 0, 0, 0.5);*/
  border-top: 1px solid #000;
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

.jodit_container .jodit_toolbar,
.jodit_container .jodit_statusbar {
  background-color: #d2ffd2;
}

.jodit_container .jodit_toolbar {
  min-height: 65px !important;
}

.jodit_container .jodit_wysiwyg {
  color: #211945;
}

.jodit_container .jodit_toolbar>li.jodit_toolbar_btn.jodit_with_dropdownlist .jodit_with_dropdownlist-trigger {
  border-top-color: #000000;
}

.jodit_container .jodit_placeholder {
  color: #21194580;
}

.filter-flex {
  display: flex;
  align-items: center;
  color: #455199;
}

.font-awesome-book {
  height: 20px !important;
  width: 20px !important;
  margin-right: 8;
}

.filter-tree-title {
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.content_title__360 {
  padding-top: 300px !important;
}

.toggle-message {
  position: absolute;
  right: 0;
  top: 30px;
}