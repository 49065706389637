.library:hover {
    border-left: 4px solid #324599;
}

.notes:hover {
    border-left: 4px solid #39B547;
}

.tags:hover {
    border-left: 4px solid #2354F9;
}

.search:hover {
    border-left: 4px solid #AC323D;
}