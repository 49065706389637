.text-area-wrapper {
    padding: 8px;
    color: #000;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 15px;
    line-height: 20px;
    resize: none;
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    height: 78px;
    margin: 3px;
}

.quill-text {
    margin-bottom: 0;
    color: #393f47;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 15px;
    line-height: 20px;
}

.ql-toolbar {
    white-space: normal !important;
}
.ql-tooltip {
    overflow: visible !important;
    top: 0 !important;
}