.collectionNote-resizable {
    display: inline-block;
    vertical-align: top;
    margin-right: 25px;
    padding: 100px 5px 5px 20px;
    border-radius: 10px;
    background-color: #fafafa;
    box-shadow: 0 21px 46px -25px rgba(0, 0, 0, 0.2);
    font-size: 15px;
    white-space: normal;
    z-index: 0;
}