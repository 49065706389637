.TrainingModal {
  display: flex;
  justify-content: flex-start;

}

.content {
  display: flex;
  justify-content: flex-start;
}

.training-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.title-linkText {
  display: flex;
  flex-direction: row;
}
.training-title-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
}

.training-title {
  font-weight: 600;
  white-space: pre;
}

.training-vid-link {
  cursor: pointer;
  color: #137bfe;
}

.training-vid-link:hover {
  text-decoration: underline;
}

.training-vid {
  height: 165px;
  width: 300px;
  border-radius: 12px;
  margin-bottom: 20px;
}
