.AnalyticsModal {
}

.content {
}

.back-btn-div {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.back-btn {
  margin: 0 4px;
  font-size: 1.2em;
}

.analytics-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.analytics-title-div {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.analytics-title {
  font-weight: 600;
  white-space: pre;
}

.analytics-vid-link {
  cursor: pointer;
  color: #137bfe;
}

.analytics-vid-link:hover {
  text-decoration: underline;
}

.analytics-vid {
  height: 165px;
  width: 300px;
  border-radius: 12px;
  margin-bottom: 20px;
}
