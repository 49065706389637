body {
  overflow: hidden;
  height: 100vh !important;
}
.mark, mark{
  background-color: inherit;
  color: inherit;
  padding: 0 !important;
}
s{
  text-decoration-line: underline;
    border-bottom: 2px solid #000;
    padding-bottom: 1px;
}
.css-2b097c-container{flex: 1}