.draggable-wrapper {
	display: inline-flex;
	height: 100%;
}

.library-resizable {
	display: inline-block;
	vertical-align: top;
	margin-right: 25px;
	padding: 180px 0px 5px 0px;
	/* padding: 180px 10px 5px 25px; */
	border-radius: 10px;
	background-color: #fafafa;
	box-shadow: 0 21px 46px --25px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	white-space: normal;
	z-index: 0;
	height: 100%;
}

.analytics-resizable {
	display: inline-block;
	vertical-align: top;
	margin-right: 25px;
	padding-bottom: 20px;
	/* padding: 180px 10px 5px 25px; */
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 21px 46px --25px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	white-space: normal;
	z-index: 0;
	height: 100%;
}

.tags-resizable {
	display: inline-block;
	vertical-align: top;
	margin-right: 25px;
	padding: 180px 10px 5px 25px;
	border-radius: 10px;
	background-color: #fafafa;
	box-shadow: 0 21px 46px --25px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	white-space: normal;
	z-index: 0;
}

.collections-resizable {
	height: 100%;
	display: inline-block;
	vertical-align: top;
	margin-right: 25px;
	padding: 180px 10px 5px 25px;
	border-radius: 10px;
	background-color: #fafafa;
	box-shadow: 0 21px 46px --25px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	white-space: normal;
	z-index: 0;
}

.search-resizable {
	display: inline-block;
	vertical-align: top;
	margin-right: 25px;
	padding: 80px 10px 5px 25px;
	border-radius: 10px;
	background-color: #fafafa;
	box-shadow: 0 21px 46px --25px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	white-space: normal;
	z-index: 0;
}

.searchFrequency-resizable {
	display: inline-block;
	vertical-align: top;
	margin-right: 25px;
	padding: 80px 10px 5px 25px;
	border-radius: 10px;
	background-color: #fafafa;
	box-shadow: 0 21px 46px --25px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	white-space: normal;
	z-index: 0;
}

.search-resizable-podcast {
	display: inline-block;
	vertical-align: top;
	margin-right: 25px;
	padding: 160px 10px 5px 25px;
	border-radius: 10px;
	background-color: #fafafa;
	box-shadow: 0 21px 46px --25px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	white-space: normal;
	z-index: 1;
}

.search-result-resizable {
	display: inline-block;
	vertical-align: top;
	margin-right: 25px;
	padding: 0px 10px 5px 25px;
	/* padding: 140px 10px 5px 25px; */
	height: 100%;
	border-radius: 10px;
	background-color: #fafafa;
	font-size: 14px;
	white-space: normal;
	z-index: 0;

	/* display: inline-block;
  vertical-align: top; */
	/* margin-right: 25px; */
	/* margin-right: 65px;
  padding: 0px 10px 5px 25px; */
	/* padding: 150px 10px 5px 25px; */
	/* border-radius: 10px;
  background-color: #fafafa;
  box-shadow: 0 21px 46px --25px rgba(0,0,0,0.2);
  font-size: 14px;
  white-space: normal;
  z-index: 1;  */
}

.chapter-resources-resizable {
	display: inline-block;
	vertical-align: top;
	margin-right: 25px;
	padding: 160px 10px 5px 25px;
	border-radius: 10px;
	background-color: #fafafa;
	box-shadow: 0 21px 46px --25px rgba(0, 0, 0, 0.2);
	font-size: 14px;
	white-space: normal;
	z-index: 1;
}

/*Main Components animations START*/
.component-enter {
	transform: translateY(-100%);
}

.component-enter.component-enter-active {
	transform: translateY(0);
	transition: transform 300ms ease-out;
}

.component-leave {
	transform: translateY(0);
}

.component-leave.component-leave-active {
	transform: translateY(-100%);
	opacity: 0;
	transition: 300ms ease-in;
}

.component-appear {
	opacity: 0;
}

.component-appear.component-appear-active {
	opacity: 1;
	transition: opacity 300ms ease-in;
}

/*Main Components animations END*/

/*Books animations START*/
.book-enter {
	transform: translateX(-100%);
}

.book-enter.book-enter-active {
	transform: translateX(0);
	transition: transform 500ms ease-out;
}

.book-leave {
	transform: translateX(0);
}

.book-leave.book-leave-active {
	transform: translateX(-100%);
	opacity: 0;
	transition: 300ms ease-in;
}

.book-appear {
	opacity: 0;
}

.book-appear.book-appear-active {
	opacity: 1;
	transition: opacity 500ms ease-in;
}

/*Books animations END*/

/*Verses animations START*/
.verse-enter {
	transform: translateX(-100%);
}

.verse-enter.verse-enter-active {
	transform: translateX(0);
	transition: transform 500ms ease-out;
}

.verse-leave {
	transform: translateX(0);
}

.verse-leave.verse-leave-active {
	transform: translateX(-100%);
	opacity: 0;
	transition: 300ms ease-in;
}

.verse-appear {
	opacity: 0;
}

.verse-appear.verse-appear-active {
	opacity: 1;
	transition: opacity 500ms ease-in;
}

/*Verses animations END*/

body {
	/*overflow: hidden;*/
}

.tag_notes_collection_text {
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-style: italic;
	font-size: 14;
}
