#radio-1 {
	-webkit-appearance: radio;
	-moz-appearance: radio;
}

#radio-2 {
	-webkit-appearance: radio;
	-moz-appearance: radio;
}

#radio-3 {
	-webkit-appearance: radio;
	-moz-appearance: radio;
}

#radio-4 {
	-webkit-appearance: radio;
	-moz-appearance: radio;
}

.filters-button-highlight {
	background-color: #324599 !important;
	color: #fff !important;
}

.button-highlight {
	background-color: #324599 !important;
	color: #fff !important;
}

.history-header:hover {
	background-color: #ffffff00 !important;
	cursor: default !important;
}
.history-header {
	font-weight: 600 !important;
	font-size: 110% !important;
	margin-bottom: 12px !important;
}
